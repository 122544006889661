import useJwt from '@/auth/jwt/useJwt'

export function toCapitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function trimEllip(text, length) {
  return text.length > length
    ? `${text.substring(0, length)}...`
    : text
}

export async function getSiteById(id) {
  const params = {
    sortField: 'title',
    sortOrder: 'asc',
    page: 1,
    size: 20,
    searchTitle: id,
  }

  await useJwt.sites(params)
    .then(response => {
      if (response.data.sites && response.data.sites.length > 0) {
        const { user_id } = response.data.sites[0]
        // this.$router.push({ name: 'dashboard-site', params: { siteId: id, userId: user_id } })
        const url = this.$router.resolve({
          name: 'dashboard-site',
          params: { siteId: id, userId: user_id },
        }).href

        window.open(url, '_blank')
      }
    })
}

export function openRouteInNewWindow(routeName, routeParams) {
  window.open(
    this.$router.resolve({ name: routeName, params: routeParams }).href,
    '_blank',
  )
}
