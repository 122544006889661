<template>
  <div>
    <div class="head-card">
      <div class="head-card-top">
        <h4>{{ partner.name }}</h4>
        <feather-icon
          v-if="closing"
          v-b-tooltip.hover.top="$t('Close')"
          icon="XIcon"
          size="18"
          class="toggle-close cursor-pointer"
          @click="$router.go(-1)"
        />
      </div>
      <hr>
      <div class="head-card-bottom">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-partner' }"
          exact
        >
          <feather-icon
            icon="GlobeIcon"
            size="16"
          />
          {{ $t('page.overview') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-partner-stats'}"
        >
          <feather-icon
            icon="BarChart2Icon"
            size="16"
          />
          {{ $t('Statistics') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-partner-logs' }"
        >
          <feather-icon
            icon="FileTextIcon"
            size="16"
          />
          {{ $t('logs') }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          :to="{ name: 'dashboard-partner-edit' }"
        >
          <feather-icon
            icon="SettingsIcon"
            size="16"
          />
          {{ $t('Settings') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
    closing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authUserData: getUserData(),
    }
  },
}
</script>
