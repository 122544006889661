<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <PartnerToolbar
        v-if="Object.keys(partner).length !== 0"
        :partner="partner"
      />
      <hr>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h4>{{ $t('Statistics') }}</h4>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="col-picker"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range'}"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @on-close="toggleRange"
              />
            </div>
          </div>
          <div v-if="authUserData.role.includes('admin')">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              size="sm"
              class="btn-icon ml-25"
              :to="{ name: 'dashboard-partner-stats' }"
            >
              V1
            </b-button>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else>
        <b-table
          small
          :items="statsV2Data"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
          striped
          foot-clone
        >
          <template #head(date)>
            {{ $t('message.tableHeader.date') }}
          </template>

          <template #head(impressions)>
            {{ $t('message.tableHeader.impressions') }}
          </template>

          <template #head(clicks)>
            {{ $t('message.tableHeader.clicks') }}
          </template>

          <template #head(revenue)>
            {{ $t('message.tableHeader.ecpm') }}
          </template>

          <template #cell(date)="row">
            <span v-if="row">
              <a
                href="#"
                :class="row.item._showDetails === true ? 'text-secondary' : ''"
                @click.prevent="showDetails(row)"
              >
                <strong>{{ getFormattedDate(new Date(row.item.date)) }}</strong>
                <feather-icon
                  class="ml-15"
                  :icon="row.item._showDetails === true ? 'ChevronDownIcon' : 'ChevronRightIcon'"
                  size="20"
                />
              </a>
            </span>
            <span v-else>
              {{ row.item.date }}
            </span>
          </template>

          <template #row-details="{ item }">
            <div v-if="item">
              <div
                v-if="loading_hourly && !statsDay[item.date]"
                class="text-center"
              >
                <b-spinner label="Loading..." />
              </div>

              <b-table
                v-else-if="!loading_hourly && statsDay[item.date].length"
                small
                :items="statsDay[item.date]"
                :fields="fields"
                responsive
                striped
                thead-class="d-none"
                table-variant="primary"
              >
                <template #cell(date)="row">
                  {{ getFormattedDateTimeZone(row.item.date) }}
                </template>
                <template #cell(impressions)="data">
                  {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
                </template>
                <template #cell(clicks)="data">
                  {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(countObjectTotals(data.value)) : 0 }}
                </template>
                <template #cell(revenue)="data">
                  {{ data.value ? currency('USD', countObjectTotals(data.value)) : 0 }}
                </template>
              </b-table>
            </div>
          </template>

          <template #cell(impressions)="data">
            {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data.value) : 0 }}
          </template>

          <template #cell(clicks)="data">
            <span
              class="text-primary cursor-pointer"
              @click="showSidebarDetails(data.item.date, 'clicks')"
            >
              {{ data.value ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(countObjectTotals(data.value)) : 0 }}
            </span>
          </template>

          <template #cell(revenue)="data">
            <span
              class="text-primary cursor-pointer"
              @click="showSidebarDetails(data.item.date, 'revenue')"
            >
              {{ currency('USD', countObjectTotals(data.value)) }}
            </span>
          </template>

          <template #foot(date)="data">
            <strong :data-label="data.label">{{ $t('Records') }}: {{ statsV2Data.length }}</strong>
          </template>

          <template #foot(impressions)="data">
            <strong :data-label="data.label">{{ totalFormat('impressions').toLocaleString('Ru-ru') }}</strong>
          </template>

          <template #foot(clicks)="data">
            <strong
              :data-label="data.label"
              class="text-primary cursor-pointer"
              @click="showSidebarTotalDetails('clicks')"
            >
              {{ totalFormat('clicks').toLocaleString('Ru-ru') }}
            </strong>
          </template>
          <template #foot(revenue)="data">
            <strong
              :data-label="data.label"
              class="text-primary cursor-pointer"
              @click="showSidebarTotalDetails('revenue')"
            >
              {{ currency('USD', totalFormat('revenue')) }}
            </strong>
          </template>
        </b-table>
      </div>
    </b-card>
    <!-- <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <PartnersStatsSidebar
        ref="sidebar"
        account-currency="USD"
        :sidebar-data="sidebarData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-sidebar>
    <b-sidebar
      id="sidebar-totals-right"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <PartnersStatsTotalSidebar
        ref="sidebar-totals"
        account-currency="USD"
        :selected-range="selectedRangeFormated"
        :sidebar-data="sidebarTotalData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-sidebar> -->
    <b-modal
      id="modal-stats"
      ref="modal-stats"
      centered
      size="lg"
      :title="modalTitle"
      ok-only
      ok-title="Ok"
      scrollable
    >
      <PartnersStatsModal
        account-currency="USD"
        :sidebar-data="sidebarData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-modal>
    <b-modal
      id="modal-stats-totals"
      ref="modal-stats-totals"
      centered
      size="lg"
      :title="modalTitleTotals"
      ok-only
      ok-title="Ok"
      scrollable
    >
      <PartnersStatsTotalModal
        account-currency="USD"
        :selected-range="selectedRangeFormated"
        :sidebar-data="sidebarTotalData"
        :sidebar-data-field="sidebarDataField"
      />
    </b-modal>
  </div>
</template>

<script>
import { getUserData } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import {
  BButton,
  BCard,
  BTable,
  BSpinner,
  VBTooltip,
  // BSidebar,
  BModal,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime, getFormattedDateTimeZone } from '@/utils/date-formatter'
import PartnerToolbar from './PartnerToolbar.vue'
// import PartnersStatsSidebar from './stats/PartnersStatsSidebar.vue'
// import PartnersStatsTotalSidebar from './stats/PartnersStatsTotalSidebar.vue'
import PartnersStatsModal from './stats/PartnersStatsModal.vue'
import PartnersStatsTotalModal from './stats/PartnersStatsTotalModal.vue'

export default {
  components: {
    BButton,
    Breadcrumbs,
    BCard,
    BTable,
    BSpinner,
    PartnerToolbar,
    // PartnersStatsSidebar,
    // PartnersStatsTotalSidebar,
    // BSidebar,
    PartnersStatsModal,
    PartnersStatsTotalModal,
    BModal,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: true,
      loading_hourly: true,
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      partner: {},
      rows: [],
      banner: [],
      fields: [
        { key: 'date', label: 'Date', tdClass: 'td-width' },
        { key: 'impressions', label: 'Impressions', tdClass: 'td-width' },
        { key: 'clicks', label: 'Clicks', tdClass: 'td-width' },
        { key: 'revenue', label: 'Revenue', tdClass: 'td-width' },
      ],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      authUserData: getUserData(),
      currency: currencyFormatter,
      getFormattedDate,
      getFormattedDateTime,
      getFormattedDateTimeZone,
      statsV2Data: [],
      statsTotalData: [],
      statsDay: {},
      sidebarData: [],
      sidebarTotalData: {},
      IsOpenSidebar: false,
      sidebarDataField: '',
      rangePicker: [],
      rangeDefault: [
        getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
        getFormattedDate(new Date()),
      ],
    }
  },
  computed: {
    selectedRangeFormated() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      return {
        from: getFormattedDate(new Date(dateFrom)),
        to: dateTo === undefined ? getFormattedDate(new Date(dateFrom)) : getFormattedDate(new Date(dateTo)),
      }
    },
    modalTitle() {
      let title = ''

      if (this.sidebarData[0] !== undefined) {
        title = `${getFormattedDate(new Date(this.sidebarData[0].date))}`
      }

      return title
    },
    modalTitleTotals() {
      return `${this.$i18n.t('date_range', { from: this.selectedRangeFormated.from, to: this.selectedRangeFormated.to })}`
    },
  },
  created() {
    this.rangePicker = this.rangeDefault
    this.getPartnerData()
    this.getStatsTotalData()
  },
  methods: {
    async getPartnerData() {
      const responseData = await useJwt.getPartner(this.$route.params.id)
      this.partner = responseData.data.partner || {}
      this.getStatsV2Data()
      this.setBreabcrumbs()
    },
    async getStatsV2Data() {
      this.loading = true
      this.statsV2Data = []
      const params = {
        campaign_id: this.$route.params.id,
        type: 'daily',
        from: this.selectedRangeFormated.from,
        ad_type: 'external',
      }

      const responseData = await useJwt.getCampaignStatsEcpm(params)
      this.statsV2Data = responseData.data.items || []
      this.loading = false
    },
    async getStatsTotalData() {
      this.statsTotalData = []
      const params = {
        campaign_id: this.$route.params.id,
        type: 'daily',
        from: this.selectedRangeFormated.from,
        ad_type: 'external',
      }

      const responseData = await useJwt.getPartnersCampaignsTotal(params)
      this.statsTotalData = responseData.data.items || []
    },
    async getStatsDay(day) {
      this.loading_hourly = true
      this.statsDay[day] = null
      const params = {
        campaign_id: this.$route.params.id,
        type: 'hourly',
        date: getFormattedDate(new Date(day)),
        ad_type: 'external',
      }

      const responseData = await useJwt.getCampaignStatsEcpm(params)
      this.statsDay[day] = responseData.data.items || null
      this.loading_hourly = false
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('RTB partners'),
          to: '/partners/',
        },
        {
          text: this.partner.name,
          to: `/partners/${this.partner._id}/`,
        },
        {
          text: this.$t('Statistics'),
          active: true,
        },
      ]
    },
    countObjectTotals(obj) {
      const calc = Object.values(obj).reduce((acc, value) => acc + value, 0)
      return calc.toFixed(2)
    },
    showDetails(row) {
      row.toggleDetails()
      if (row.item._showDetails) {
        if (!this.statsDay[row.item.date]) {
          this.getStatsDay(row.item.date)
        }
      }
    },
    totalFormat(field) {
      let total = 0
      this.statsV2Data.forEach(i => {
        if (typeof i[field] === 'object') {
          total += +this.countObjectTotals(i[field])
        } else {
          total += i[field]
        }
      })
      return total
    },
    async showSidebarDetails(date, field) {
      this.sidebarData = []
      this.sidebarDataField = field

      const currentItem = this.statsV2Data.filter(i => i.date === date)
      if (currentItem.length > 0) {
        this.sidebarData = currentItem
        const ids = Object.keys(currentItem[0][field]).filter(key => key !== 'others')
        if (ids.length > 0) {
          await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
        }
        this.$refs['modal-stats'].show()
      }
    },
    async showSidebarTotalDetails(field) {
      this.sidebarTotalData = {}
      this.sidebarDataField = field

      const newItems = this.getRangeTotalsByType(this.statsTotalData, field)
      if (Object.keys(newItems).length > 0) {
        const ids = Object.keys(newItems).filter(key => key !== 'others')
        if (ids.length > 0) {
          await store.dispatch('dashboard-sites-stats/fetchSiteNamesById', ids)
        }
        this.sidebarTotalData = newItems
        this.$refs['modal-stats-totals'].show()
      }
    },
    getRangeTotalsByType(data, field) {
      return data.reduce((acc, obj) => {
        Object.entries(obj[field]).forEach(([key, value]) => {
          acc[key] = (acc[key] || 0) + value
        })
        return acc
      }, {})
    },
    toggleRange(e) {
      this.rangePicker = e
      const { from, to } = this.selectedRangeFormated
      this.getStatsV2Data()
      this.getStatsTotalData()
      this.$router.replace({
        name: 'dashboard-partner-stats-v2',
        query: { from, to },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
<style>
[dir] .table-responsive .table-responsive {
  margin-bottom: 0!important;
}

[dir] .table.b-table > tbody > tr.b-table-details > td {
  padding-left: 0!important;
  padding-right: 0!important;
  padding-bottom: 0!important;
  border-bottom: 1px solid #776bf0
}

[dir] .table.b-table > tbody > tr.b-table-details .table-striped tbody tr:nth-of-type(odd) {
  background: #ebebeb;
}

[dir] .table.b-table > tbody td.td-width {
  width: 11%;
}
</style>
<style scoped>
  [dir] .b-table-has-details .btn-flat-primary {
    padding: 0!important;
  }
</style>
