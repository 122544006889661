<template>
  <div>
    <b-container>
      <div v-if="Object.keys(sidebarData).length !== 0 && !sidebarLoading">
        <div
          v-if="Object.keys(sidebarData).length > 0"
          class="d-flex justify-content-between"
        >
          <strong>{{ $t(`stats.${sidebarDataField}`) }}</strong>
          <strong v-if="sidebarDataField === 'revenue'">{{ currency(accountCurrency, countObjectTotals(sidebarData)) }}</strong>
          <strong v-else>{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(countObjectTotals(sidebarData)) }}</strong>
        </div>
        <div
          v-else
          class="d-flex justify-content-between"
        >
          <strong>{{ $t(`stats.${sidebarDataField}`) }}</strong>
          <strong v-if="sidebarDataField === 'revenue'">{{ currency(accountCurrency, sidebarData) }}</strong>
          <strong v-else>{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(sidebarData) }}</strong>
        </div>
        <div
          v-for="(item, idx) in Object.keys(sortObjectByValues(sidebarData))"
          :key="item"
          class="stats-item d-flex justify-content-between"
          :class="{
            even: idx % 2 !== 0,
            odd: idx % 2 === 0
          }"
        >
          <small
            :class="{'text-primary cursor-pointer': item !== 'others'}"
            @click="getSiteById(item)"
          >
            {{ siteNameByIdFromStore(item) }}
          </small>
          <small v-if="sidebarDataField === 'revenue'">{{ currency(accountCurrency, sidebarData[item]) }}</small>
          <small v-else>{{ new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(sidebarData[item]) }}</small>
        </div>
      </div>
      <div
        v-else-if="sidebarLoading"
        class="text-center"
      >
        <b-spinner label="Loading..." />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate from '@/utils/date-formatter'
import { getSiteById } from '@/utils/helpers'

import {
  BContainer,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BSpinner,
  },
  props: {
    sidebarData: {
      type: Object,
      required: true,
    },
    accountCurrency: {
      type: String,
      default: '',
    },
    sidebarDataField: {
      type: String,
      default: '',
    },
    selectedRange: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currency: currencyFormatter,
      getFormattedDate,
      getSiteById,
      sidebarLoading: false,
      toggleData: {
        revenue: [],
        clicks: [],
      },
    }
  },
  computed: {
    ...mapGetters('dashboard-sites-stats', ['siteNameByIdFromStore']),
  },
  watch: {
    sidebarData(newValue) {
      if (newValue.length > 0) {
        this.sidebarLoading = false
      } else {
        this.sidebarLoading = true
        setTimeout(() => { this.sidebarLoading = false }, 1000)
      }
    },
  },
  methods: {
    filteredFields(item) {
      const { clicks, revenue, impressions } = item

      return {
        clicks,
        revenue,
        impressions,
      }
    },
    countObjectTotals(obj) {
      const calc = Object.values(obj).reduce((acc, value) => acc + value, 0)
      return calc.toFixed(2)
    },
    sortObjectByValues(obj) {
      const entries = Object.entries(obj)
      const othersEntry = entries.find(entry => entry[0] === 'others')
      const filteredEntries = entries.filter(entry => entry[0] !== 'others')
      filteredEntries.sort((a, b) => b[1] - a[1])
      if (othersEntry) {
        filteredEntries.push(othersEntry)
      }

      return Object.fromEntries(filteredEntries)
    },
    formatTitle(key) {
      if (key !== 'revenue') {
        return `${this.$i18n.t(`stats.${key}`)} ${this.countObjectTotals(this.sidebarData[key])}`
      }

      return `${key} ${this.currency(this.accountCurrency, this.countObjectTotals(this.sidebarData[key]))}`
    },
    toggleDetails(value, field) {
      const idx = this.toggleData[field].indexOf(value)

      if (idx !== -1) {
        this.toggleData[field].splice(idx, 1)
      } else {
        this.toggleData[field].push(value)
      }
    },
  },
}
</script>

<style scoped>
#modal-stats-totals .stats-item {
  padding: 4px;
  border-top: 1px solid #ebe9f1
}

#modal-stats-totals .stats-item.odd {
  background-color: #fafafc;
}

#modal-stats-totals .card .card-body .stats-item.even {
  background-color: #ffffff;
}
</style>
